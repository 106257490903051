import React from 'react'
import { Container } from 'reactstrap'
import CareerRequirements from '../components/CareerRequiremetns/CareerRequirements'
import Layout from '../components/layout/layout'
import { Helmet } from "react-helmet"



const JobDescriptionFullStackUS = () => {
    return (
        <div>
            <Helmet title='DNNae | Quality Assurance JD' />
            <Layout
                containerStyle={{
                    position: 'relative'

                }}
                buttonStatus={false}
                selectedState=''
            >

                <Container
                    
                    style={{
                    maxWidth: '1920px'
                    }}
                >
                <CareerRequirements
                
                    
                    jobTitle='Quality Assurance Engineer'
                    jobTypeAndPlace='Contract, Islamabad'
                    weAreLooking='We are looking for a Quality Assurance Engineer who will partner with both the business and technical teams to innovate and deliver new features and enhancements for our product. You will create and/or modify complex solutions that meet the expectations of users. You will also have a thorough knowledge of the entire system and work with product, engineering and other teams in the organization to deliver the best experience to the customers.'
                    responsibilitiesArray={[
                        'Create test plans, maintain documentation and perform testing on multiple devices, platforms, and browsers',
                        'Plan and Execute verification of features broken down into components for weekly releases.',
                        'Takes full ownership of all QA artifacts',
                        'Points out execution gaps and completely owns process improvements.',
                        'Actively looks into all ES and customer-facing issues being reported for your organization',
                        'Aid in retrospecting possible customer issues with the leads',
                        'Identify, analyze, and document defects, questionable functions, errors, and inconsistencies in web and mobile applications as well as ELD functions, outputs, online screens, and content',
                        'Take ownership of E2E testing',
                        
                    ]}
                    
                    whatYouNeedArray={[
                        '2+ years of experience in Software Testing (web application development will be preferable)',
                        'Flare for testing map-based real-time systems',
                        'Knowledge of Agile methodologies, SCRUM, and Agile Testing Life Cycles',
                        'Ability to take ownership of all manual testing tasks',
                        'Experience in both manual testing and automation',
                        'Ability to understand Software Design Specifications',
                        'Ability to create boundary value, positive, and negative test cases',
                        'Knowledge of issue tracking tools (Asana will be preferable)',
                        'Knowledge of OOP, Data Structures, and Algorithms',
                        'Keen towards programing languages and code reviews',

                    ]}
                >

                </CareerRequirements>

                </Container>
            </Layout>
        </div>
    )
}

export default JobDescriptionFullStackUS
